import isPlainObject from 'lodash/isPlainObject';
import each from 'lodash/each';
import AbstractEvaluationScope from './AbstractEvaluationScope';
import { evaluateFormulas } from './utils';

class EvaluationScope extends AbstractEvaluationScope {
  getUser(userId) {
    return this.users && this.users[userId];
  }

  getQuestionnaire() {
    return this.questionnaire;
  }

  getAnswer(id) {
    return this.answers && this.answers[id];
  }

  getVariable(id) {
    return this.variables && this.variables[id];
  }

  forEachAnswer(action) {
    return each(this.answers, action);
  }

  forEachVariable(action) {
    return each(this.variables, action);
  }

  evaluateAllFormulas(options) {
    return evaluateFormulas(this, options);
  }

  copyWithFormValues(formValues) {
    return new EvaluationScope({
      ...this,
      answers: formValues,
    });
  }

  static create({
    questionnaire,
    answersSheet,
    variables,
    formValues = answersSheet ? answersSheet.toFormValues() : {},
  } = {}) {
    let scopeVariables = answersSheet
      ? answersSheet.getEvaluationScopeVariables()
      : {};
    if (isPlainObject(variables)) {
      scopeVariables = {
        ...scopeVariables,
        variables,
      };
    } else if (typeof variables === 'function') {
      scopeVariables = variables(scopeVariables, answersSheet);
    }
    return new this({
      questionnaire,
      variables: scopeVariables,
      answers: formValues,
    });
  }

  /**
   * Extract variables from the given form values object. Extracting values
   * from nested questions inside a collection is currently not supported.
   * @param {Object} formValues
   * @param {Questionnaire} [questionnaire]
   * @returns {Object}
   */
  static mapFormValuesToVariables(formValues, questionnaire) {
    const evaluationScope = new this({
      questionnaire,
      answers: formValues,
    });
    return evaluationScope.evaluateVariables();
  }

  /**
   * Returns an object mapping variable id to corresponding value.
   * @param {AnswersSheet} answersSheet
   * @returns {Object}
   */
  static extractVariables(answersSheet, questionnaire) {
    const evaluationScope = this.create({
      answersSheet,
      questionnaire,
    });
    return evaluationScope.evaluateVariables();
  }

  /**
   * Return all responses that can be evaluated. This will include both
   * question type "Formula" and default values for selected questions.
   * @param {AnswersSheet} answersSheet
   * @param {Questionnaire} questionnaire
   * @param {Object} [scopeOptions]
   */
  static evaluateFinalResponses(answersSheet, questionnaire, scopeOptions) {
    const evaluationScope = this.create({
      ...scopeOptions,
      answersSheet,
      questionnaire,
    });
    return answersSheet.mergeResponses(
      questionnaire.createResponsesFromFormValues(
        evaluateFormulas(evaluationScope),
      ),
    );
  }
}

export default EvaluationScope;
